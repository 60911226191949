import { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

const PaymentForm = ({ setPageShowing, cart, username }) => {
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const navigate = useNavigate();

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Make sure to disable form submission until Stripe.js has loaded.
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Return URL where the customer is redirected after payment is processed.

                // return_url: `${process.env.REACT_APP_CLIENT_URL}/checkout`,
            },
            // Allows you to skip return_url
            redirect: "if_required",
        });
        const paymentIntent = result.paymentIntent;

        if (paymentIntent && paymentIntent.status === "succeeded") {
            // Show a success message to the customer without redirecting
            console.log("paymentIntent.status succeeded")

            const orderInfo = {
                orders: cart,
                username: username,
            };

            await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
                method: "PUT",
                mode: "cors",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify( orderInfo ),
            })
            setPageShowing("checkout-final");
        }

        // This code is only reached if there's an immediate error when confirming payment.
        // Otherwise, your customer will be redirected to the `return_url`
        else if (result.error) {
            if (result.error.type === "card_error" || result.error.type === "validation_error") {
                setMessage(result.error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        }


        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    };

    return (
        <form className="PaymentForm col-11 col-lg-10 col-xl-9 mx-auto" id="payment-form" onSubmit={handleSubmit}>
            {/* <div className="col-2 d-flex justify-content-start mb-3">
                <button onClick={() => setPageShowing("shipping-info")} className="btn btn-outline-primary mt-4">
                    Back
                </button>
            </div> */}
            <div className="col-8 mx-auto fs-4 my-3 text-center fw-bold">Application is in test mode. <br /> Please do not input real card details.</div>

            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <div className="row mx-auto my-3">
                {/* Show any error or success messages */}
                {message && (
                    <div id="payment-message" className="text-dark fs-4">
                        {message}
                    </div>
                )}
                <div className="col col-md-6 mx-auto">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg mt-4"
                        disabled={isLoading || !stripe || !elements}
                        id="submit"
                    >
                        <span id="button-text">
                            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default PaymentForm;

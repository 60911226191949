import { Link } from "react-router-dom";

import CartUpdater from "../context/cartUpdater";
import { formatPrice } from "../util/functions";
import "../ProductCard.css";
import OrderBar from "./OrderBar";
import NewOrderBar from "./NewOrderBar";

const ProductCard = (props) => {
    const product = props.productData;
    const orders = props.orders;

    let cents = formatPrice(product.price.fraction);

    const findOrder = (product) => {
        let order = orders.find((o) => o.product_id === product.product_id);
        return order;
    }

    return (
        <div
            className="ProductCard col-10 col-lg-6 col-xxl-4 px-4 mb-4 text-dark d-flex flex-column justify-content-center mx-auto"
            id="card-full"
            // style={{ height: "18rem" }}
        >
            <div className="ProductCardInner row border h-100">
                <Link
                    to={`/products/${product.product_id}`}
                    className="card-picture-wrapper col-4 d-flex justify-content-center align-items-center border-end p-3 bg-light"
                >
                    <img src={product.image} className="card-picture" alt="product" />
                </Link>
                <div className="col-8 pt-2 d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column justify-content-around text-start" style={{height: "80%"}}>
                        <Link className="card-upper text-dark" to={`/products/${product.product_id}`}>
                            <h5
                            className="card-product-name fw-bold lead fs-4 mb-2 text-start"
                                style={{ fontSize: "1.2rem" }}
                            >
                                {product.name}
                            </h5>
                            <p className="card-price fs-6 mb-0">
                                <span className="fs-2 display-3">${product.price.whole}.</span>
                                {cents}
                            </p>
                        </Link>
                        <div className="card-lower row">
                            {/* <p className="card-min-qty mb-1 mt-2 fs-5">
                                Qty for order
                            </p> */}
                            <div className="card-order-bar col-7 align-items-start">
                                {/* <OrderBar barStyle={{height: "1rem", width: "100%", marginLeft: "0rem"}} textStyle={{textAlign: "start", fontSize: "1rem"}}/> */}
                                <OrderBar product={product} order={orders.find((o) => o.product_id === product.product_id)} barStyle={{height: "1rem", width: "100%", marginLeft: "0rem"}}/>
                            </div>
                            <div className="col-5 d-flex">
                                <CartUpdater item={{ product: product, qty: 1, image: product.image }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;

import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutShipping from "./CheckoutShipping";
import CheckoutPayment from "./CheckoutPayment";
import CheckoutComplete from "./CheckoutComplete";

import UserContext from "../../context/userContext";

import "./stripe.css";

const pk = process.env.REACT_APP_STRIPE_PK;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(pk);

const getCartTotal = (cart) => {
    let t = 0;
    cart.forEach((item) => {
        t += item.product_price * item.qty;
    });
    console.log("getCartTotal", t);
    return t * 100;
};

const Checkout = () => {
    const [clientSecret, setClientSecret] = useState("");
    const [pageShowing, setPageShowing] = useState("loading");

    const [cart, setCart] = useState([]);
    const [userData, setUserData] = useState({});
    const [orderInfo, setOrderInfo] = useState({});
    const [cookies] = useCookies(['token']);
    const { user } = useContext(UserContext);

    const verifyCookie = async () => {
        console.log("ran verifyCookie (checkout.js)");
        if (!cookies.token || cookies.token === 'undefined') {
            // navigate("/login");
            // cookies.updateCookies();
            // console.log("not logged in, no token in cookies.");
        }
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        const { status, authUser, authCart } = data

        console.log("status: ", status)
        console.log("authUser: ", authUser)
        if (status === "true" || status === true) {
            await setCart(authCart);
            await getUserData(authUser);
            await setPageShowing("shipping-info")
        }
    };

    const getUserData = async (username) => {
        // let res = await fetch(`${process.env.REACT_APP_API_URL}/users/${userData.email}`)
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/${username}`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let data = await res.json()
        if (data.account_type){
            console.log("getUserData", data)
            setUserData(data)
        }
        else {
            console.log("User not found")
        }
    }



    useEffect(() => {
        if (!userData.username || cart.length === 0) {
            verifyCookie();
        }
        // Create PaymentIntent as soon as the page loads
        else if (!clientSecret) {
            console.log("CREATED INITIAL PAYMENT INTENT");
            fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ items: cart, total: getCartTotal(cart) }),
            })
                .then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret));
        }
    }, [cart]);

    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret,
        appearance,
    };

    if (!user) {
        return (
            <div className="Checkout d-flex flex-column justify-content-center col-md-6 col-10 mx-auto my-auto">
                <div className="row">
                    <h1 className="display-4 mb-4">You are not logged in.</h1>
                    <h2 className="lead fs-4">Please log in or create an account to get started with Marafyde.</h2>
                </div>
                <div className="row justify-content-center mt-3">
                    <Link className="btn btn-lg btn-primary col-xl-3 col-md-5 col-5 my-1 px-2 mx-3" to="/login">
                        Log in
                    </Link>
                    <Link
                        className="btn btn-lg btn-outline-primary col-xl-3 col-md-5 col-5 my-1 px-2 mx-3"
                        to="/signup"
                    >
                        Sign up now
                    </Link>
                </div>
            </div>
        );
    }

    if (pageShowing === "loading") {
        return <></>;
    }

    else if (pageShowing === "shipping-info") {
        return (
            <div className="Checkout mt-2">
                <CheckoutShipping setPageShowing={setPageShowing} cart={cart} user={userData} verifyCookie={verifyCookie} orderInfo={orderInfo} setOrderInfo={setOrderInfo} />
            </div>
        );
    } else if (pageShowing === "payment-info") {
        return (
            <div className="Checkout mt-2">
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutPayment
                            clientSecret={clientSecret}
                            orderInfo={orderInfo}
                            setClientSecret={setClientSecret}
                            setPageShowing={setPageShowing}
                            cart={cart}
                            user={userData}
                        />
                    </Elements>
                )}
            </div>
        );
    } else if (pageShowing === "checkout-final") {
        return (
            <div className="Checkout mt-2">
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutComplete cart={cart} user={userData} />
                    </Elements>
                )}
            </div>
        );
    }
};

export default Checkout;
